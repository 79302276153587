<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <!-- <div class="app__inner" [class.anim--in]="loading$()"> -->
  @if (loaded$()) {
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      [menuActive]="menuOpen$()"
      [upperMenu]="('topmenu-upper' | fromMenu)?.error ? [] : ('topmenu-upper' | fromMenu)"
      [menu]="('topmenu-main' | fromMenu)?.error ? [] : ('topmenu-main' | fromMenu)"
    >
      @if (alert$(); as alert) {
        <app-alert-banner slot="alert" [alert]="alert"></app-alert-banner>
      }
    </app-topbar>
  }

  <div class="app__inner">
    @if (loaded$() && !loading$()) {
      <div [class.anim--in]="loading$()" [@fadeAnimation]="outlet.activatedRouteData">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    }
  </div>

  @if (loaded$() && !loading$()) {
    <app-footer
      class="position-relative z-2"
      [domain]="domainName"
      [socials]="socials$()"
      [footerMenuLeft]="'footer-menu-links' | getMenu"
      [footerMenuRight]="'footer-menu-rechts' | getMenu"
    ></app-footer>
  }
</div>

<app-loader-indicator [loading]="loading$()"></app-loader-indicator>

@if (loaded$() && !loading$()) {
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
}
<!-- </div> -->

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
